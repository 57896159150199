<template>
  <span>
    <DxGrid
      :canAdd="false"
      :isEditable="false"
      :data-source="dataSource"
      @on-delete="(v) => onDelete(v)"
    >
      <template #columns>
        <DxColumn data-field="user_id" caption="Name" />
        <DxColumn data-field="remarks" caption="Remarks" />
      </template>
    </DxGrid>
  </span>
</template>

<script>
import timelineHeaderApi from '@api/timeline_headers';
import timelineApi from '@api/timelines';
import { queryParameters } from '@/schema';

export default {
  name: 'RFQCommentsTab',
  components: {},
  data: () => ({
    dataColumns: [],
    dataSource: [],
  }),
  mounted() {
    if (+this.$route.query.id) {
      this.loadTimelineHeader();
    }
  },
  methods: {
    onDelete(data) {
      console.log(data);
    },
    loadTimelineHeader() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      params.filterExt = `header_id=${this.$route.query.id}`;

      timelineHeaderApi
        .list(params)
        .then(({ data }) => {
          if (data && data.length > 0) {
            this.loadTimelines(data[0].id);
          }
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadTimelines(id) {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      params.sort = 'created_at';
      params.filterExt = `header=${id}`;

      timelineApi
        .list(params)
        .then(({ data }) => {
          this.dataSource = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
  },
};
</script>

<style></style>
