<template>
  <span>
    <DxGrid
      :canAdd="false"
      :isEditable="false"
      :data-source="dataSource"
      @on-delete="(v) => onDelete(v)"
    >
      <template #columns>
        <DxColumn data-field="user_id" caption="Name" />
        <DxColumn data-field="action" caption="Actions" />
        <DxColumn data-field="action_time" caption="Date/Time" />
      </template>
    </DxGrid>
  </span>
</template>

<script>
import auditLogApi from '@api/audit_logs';
// @todo change from utils classes to global-function
import { unixToDisplayDateTime } from '@/utils/classes/dates';
import { queryParameters } from '@/schema';

export default {
  name: 'ServiceRequestLogsComponent',
  components: {},
  data: () => ({
    dataSource: [],
    dataColumns: [],
  }),
  mounted() {
    if (+this.$route.query.id) {
      this.loadLogs();
    }
  },
  methods: {
    loadLogs() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      params.filterExt = `header_id=${this.$route.query.id},model='Service Request'`;

      auditLogApi
        .list(params)
        .then(({ data }) => {
          data.forEach((e) => {
            e.action = e.log.action;
            e.action_time = unixToDisplayDateTime(e.updated_at);
          });

          this.dataSource = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onDelete(data) {
      //
    },
  },
};
</script>

<style></style>
